<template>
  <div
    v-if="$parent.checkActive(1) && !$parent.showCustom"
    class="notifyGroupWizardStepOne mb-3"
  >
    <template v-if="$parent.hasMissingLanguages">
      <div class="middle-position mb-5">
        <p
          class="red"
          style="text-align: center"
        >
          There are no translations in the required languages {{ $parent.languages }} for this template ({{ $parent.selectedTemplate.name }}). Click Refresh, when done.
        </p>
        <router-link
          target="_blank"
          :to="`/smsmanagement/template/${ $parent.selectedTemplate.id }`"
          class="btn btn-sm btn-primary ml-auto mr-auto"
        >
          Go to Message Management
        </router-link>
        <button
          class="btn btn-sm btn-primary mt-2 ml-auto mr-auto"
          type="button"
          @click="$parent.setCurrent($parent.selectedTemplate, false)"
        >
          <font-awesome-icon
            class="mr-1"
            icon="sync-alt"
          />
          <span>
            {{ $t('refresh') }}
          </span>
        </button>
      </div>
    </template>
    <template
      v-else
    >
      <div class="row mb-4">
        <div class="col-2 alignCenter">
          <div class="cell">
            <font-awesome-icon
              class="mb-4 bigText"
              icon="user-tie"
              style="vertical-align: middle;"
            />
            <br>
            <span>{{ $t('support') }}</span>
          </div>
        </div>
        <div class="col-7 mt-2">
          <div class="cell">
            <template
              v-for="(message, index) in $parent.messageList"
            >
              <div
                :key="'msg' + index"
                :class="['bubble', {'mt-3': index != 0}]"
              >
                <label class="p-0 m-0 font-weight-bold mr-2">{{ $t('template') }}:</label><span>{{ $parent.selectedTemplate.installationType }}:&nbsp;{{ $parent.selectedTemplate.name }}</span>
                <template v-if="message.subject">
                  <hr>
                  <label class="p-0 m-0 font-weight-bold mr-2">{{ $t('subject') }} ({{ message.language }}):</label><span>{{ message.subject }}</span>
                </template>
                <hr>
                <label class="p-0 m-0 font-weight-bold mr-2">{{ $t('message') }} ({{ message.language }}):</label><span>{{ message.message }}</span>
              </div>
            </template>
          </div>
        </div>
        <div class="col-1">
          <div class="cell">
            <font-awesome-icon
              class="mt-4 mediumText"
              icon="long-arrow-alt-right"
              style="vertical-align: middle;"
            />
          </div>
        </div>
        <div class="col-2 alignCenter">
          <div class="cell">
            <font-awesome-icon
              class="mb-4 bigText"
              icon="user-tie"
              style="vertical-align: middle;"
            />
            <br>
            <div>
              <span>{{ `${$parent.roleRecipients.length} Recipients` }}</span>
              <font-awesome-icon
                class="ml-2"
                icon="external-link-square-alt"
                @click="$parent.openRecipientsModal()"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'NotifyGroupWizardStepOne'
}
</script>

<style>
.middle-position {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.scrollable {
  max-height: 300px;
  margin-bottom: 10px;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
}
</style>